var render = function render(){var _vm=this,_c=_vm._self._c;return _c('custom-dialog',{attrs:{"visible":_vm.visible,"title":_vm.title,"width":"40%"},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.colseDialog,"submit":_vm.submit}},[_c('div',{staticClass:"editTitle"},[_vm._v("基本信息")]),_c('div',{staticClass:"edit-main"},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_c('el-form',{ref:"editCopanyForm",attrs:{"model":_vm.editForm,"label-width":"120px","rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":"公司名称：","prop":"compName"}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入公司名称"},model:{value:(_vm.editForm.compName),callback:function ($$v) {_vm.$set(_vm.editForm, "compName", $$v)},expression:"editForm.compName"}})],1),_c('el-form-item',{attrs:{"label":"所属机构","prop":"orgaIdArr"}},[_c('el-cascader',{staticClass:"block-select",attrs:{"size":"small","options":_vm.copanyListInfo,"popper-class":"custom-cascader","filterable":"","props":{
                checkStrictly: true,
                expandTrigger: 'hover',
              }},on:{"change":_vm.cascaderEditChange},model:{value:(_vm.editForm.orgaIdArr),callback:function ($$v) {_vm.$set(_vm.editForm, "orgaIdArr", $$v)},expression:"editForm.orgaIdArr"}})],1),_c('el-form-item',{attrs:{"label":"省:","prop":"areaParentId"}},[_c('el-select',{staticClass:"block-select",attrs:{"size":"small","filterable":"","placeholder":"请选择所在省"},on:{"change":_vm.changeAreaParent},model:{value:(_vm.editForm.areaParentId),callback:function ($$v) {_vm.$set(_vm.editForm, "areaParentId", $$v)},expression:"editForm.areaParentId"}},_vm._l((_vm.areaParentList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"市:","prop":"areaId"}},[_c('el-select',{staticClass:"block-select",attrs:{"size":"small","filterable":"","placeholder":"请选择所在市"},on:{"change":_vm.changeArea},model:{value:(_vm.editForm.areaId),callback:function ($$v) {_vm.$set(_vm.editForm, "areaId", $$v)},expression:"editForm.areaId"}},_vm._l((_vm.areaList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"地址:","prop":"compAddress"}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入地址"},on:{"input":_vm.updateMapCenter},model:{value:(_vm.editForm.compAddress),callback:function ($$v) {_vm.$set(_vm.editForm, "compAddress", $$v)},expression:"editForm.compAddress"}})],1)],1)],1),_c('el-col',{attrs:{"span":12}},[_c('baidu-map',{staticClass:"mapbox",attrs:{"center":_vm.center,"zoom":_vm.zoom,"scroll-wheel-zoom":true},on:{"ready":_vm.handler,"click":_vm.getPoint}},[_c('bm-marker',{attrs:{"position":{
              lng: _vm.editForm.compLongitude,
              lat: _vm.editForm.compLatitude,
            }}})],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }