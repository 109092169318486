<template>
  <custom-dialog
    :visible.sync="visible"
    :title="title"
    @close="colseDialog"
    @submit="submit"
    width="40%"
  >
    <div class="editTitle">基本信息</div>
    <div class="edit-main">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form
            :model="editForm"
            label-width="120px"
            :rules="rules"
            ref="editCopanyForm"
          >
            <el-form-item label="公司名称：" prop="compName">
              <el-input
                size="small"
                v-model="editForm.compName"
                placeholder="请输入公司名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属机构" prop="orgaIdArr">
              <el-cascader
                size="small"
                class="block-select"
                v-model="editForm.orgaIdArr"
                :options="copanyListInfo"
                popper-class="custom-cascader"
                filterable
                :props="{
                  checkStrictly: true,
                  expandTrigger: 'hover',
                }"
                @change="cascaderEditChange"
              >
              </el-cascader>
            </el-form-item>
            <!-- <el-form-item label="所属机构" prop="orgaId">-->
            <!--              <el-input size="small" v-model="editForm.orgaId" placeholder="请输入公司名称"></el-input>-->
            <!--            </el-form-item> -->
            <!--            <el-form-item label="公司代码" prop="compCode">-->
            <!--              <el-input size="small" v-model="editForm.compCode" placeholder="请输入公司代码"></el-input>-->
            <!--            </el-form-item>-->
            <el-form-item label="省:" prop="areaParentId">
              <el-select
                class="block-select"
                size="small"
                filterable
                v-model="editForm.areaParentId"
                placeholder="请选择所在省"
                @change="changeAreaParent"
              >
                <el-option
                  v-for="item in areaParentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="市:" prop="areaId">
              <el-select
                class="block-select"
                size="small"
                filterable
                v-model="editForm.areaId"
                placeholder="请选择所在市"
                @change="changeArea"
              >
                <el-option
                  v-for="item in areaList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="地址:" prop="compAddress">
              <el-input
                size="small"
                v-model="editForm.compAddress"
                @input="updateMapCenter"
                placeholder="请输入地址"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-col>

        <el-col :span="12">
          <baidu-map
            :center="center"
            :zoom="zoom"
            class="mapbox"
            :scroll-wheel-zoom="true"
            @ready="handler"
            @click="getPoint"
          >
            <bm-marker
              :position="{
                lng: editForm.compLongitude,
                lat: editForm.compLatitude,
              }"
            ></bm-marker>
          </baidu-map>
        </el-col>
      </el-row>
    </div>
  </custom-dialog>
</template>

<script>
import basic from "../../api/modules/basic";
import CustomDialog from "../../components/common/CustomDialog.vue";
import {
  organizationCascaders,
  phoneReg,
  organizationCascader,
} from "../../utils/tool";
import organizationTree from "../../api/modules/organizationTree";
//   import config from "../../../api/config";
export default {
  name: "projectEdit",
  props: ["visible", "title", "item"],
  components: { CustomDialog },
  watch: {
    item(newVal, oldVal) {
      if (newVal.compId) {
        this.editForm = newVal;
        this.orgaId = newVal.orgaId;
        let item = newVal;
        if (item.orgaPathIds && item.orgaPathIds.match("-")) {
          // this.editForm.orgaIdArr = item.orgaPathIds.split('-').map(item => Number(item))
          const splitArray = item.orgaPathIds.split("-");
          const numericArray =
            splitArray[splitArray.length - 1] === ""
              ? splitArray.slice(0, -1)
              : splitArray;
          let orgaIdArr = numericArray.map((item) => Number(item));
          this.editForm.orgaIdArr = orgaIdArr;
          // if(orgaLevel == 0){
          //   this.editForm.orgaIdArr = orgaIdArr[1]

          // }else if(orgaLevel == 1){

          //   let a = orgaIdArr.shift()
          //   this.editForm.orgaIdArr = orgaIdArr

          // }else {

          //   this.editForm.orgaIdArr = [orgaIdArr[2]]
          // }
        } else if (item.orgaPathIds) {
          this.editForm.orgaIdArr = [Number(item.orgaPathIds)];
        }
        if (newVal.areaParentId) {
          this.getAreaInfo(newVal.areaParentId);
        }
      } else {
        this.editForm = {
          compName: "",
          compCode: "",
          areaParentId: "",
          areaId: "",
          compAddress: "",
          orgaId: 0,
        };
      }
      this.resetFields();
    },
  },
  data() {
    return {
      center: { lng: 116.404, lat: 39.915 },
      zoom: 15,
      editForm: {
        compName: "",
        // compCode: "",
        areaParentId: "",
        areaId: "",
        compAddress: "",
        orgaIdArr: [],
      },
      rules: {
        compName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        areaParentId: [
          { required: true, message: "请选择省", trigger: "change" },
        ],
        areaId: [{ required: true, message: "请选择市", trigger: "change" }],
        orgaIdArr: [
          { required: true, message: "请选择所属机构", trigger: "change" },
        ],
        compAddress: [
          { required: true, message: "请输入地址", trigger: "change" },
        ],
      },
      copanyListInfo: [],
      options: [],
      areaParentList: [], // 省
      areaList: [], //市
      orgaId: "",
      geocoder: null,
      // uploadUrl: config.uploadUrl,
    };
  },
  mounted() {
    
    this.getAreaInfo(0);
    this.getCopanyInfo();
  },
  methods: {
    updateMapCenter() {
      this.geocoder = new BMap.Geocoder();
    const address = this.editForm.compAddress;
    if (!address || address.trim() === '') return;

    this.geocoder.getPoint(address, (point) => {
      if (point) {
        this.center = { lng: point.lng, lat: point.lat };
        this.editForm.compLongitude = point.lng;
        this.editForm.compLatitude = point.lat;
      } else {
        this.$message.error('未能找到该地址，请检查输入');
      }
    }, '北京市');
  },
    getAreaInfo(id) {
      let param = { pid: id };
      basic.getAreaList(param).then((res) => {
        console.log(res);
        if (id == 0) {
          this.areaParentList = res.data;
        } else {
          this.areaList = res.data;
        }
      });
    },
    getCopanyInfo() {
      organizationTree.getOrganizationCompanyTree().then((res) => {
        // 递归遍历 生成 {value, label, children}

        // let data = organizationCascaders(res.data);
        // console.log('data',data);

        this.copanyListInfo = organizationCascader(res.data);
      });
    },
    cascaderEditChange(val) {
      console.log("val", val);

      let valLen = val.length;
      this.editForm.orgaId = val[valLen - 1];
    },
    changeAreaParent(id) {
      // console.log(id)
      this.editForm.areaId = "";
      this.getAreaInfo(id);
      // 地图的
      this.areaParentList.forEach((item) => {
        if (item.id === id) {
          this.center = item.name;
        }
      });
    },
    changeArea(id) {
      // 市切换
      // 地图的
      this.areaList.forEach((item) => {
        if (item.id === id && item.name != "市辖区") {
          this.center = item.name;
        }
      });
    },
    submit() {
      this.$refs.editCopanyForm.validate((valid) => {
        if (valid) {
          if (this.editForm.compId) {
            // 编辑
            this.editForm.orgaId = this.orgaId;
            console.log("this.editForm", this.editForm);

            basic.updateCompany(this.editForm).then((res) => {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.$emit("done");
            });
          } else {
            // 新增
            console.log("this.editForm", this.editForm);
            basic.newCompany(this.editForm).then((res) => {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.$emit("done");
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    colseDialog() {
      this.$emit("close");
    },
    handler({ BMap, map }) {
      let mapStyle = {
        style: "midnight",
      };
      map.setMapStyle(mapStyle);
    },
    getPoint(e) {
      //点击地图获取一些信息，
      // this.show = true;
      this.editForm.compLongitude = e.point.lng; //通过  e.point.lng获取经度
      this.editForm.compLatitude = e.point.lat; //通过  e.point.lat获取纬度
      let geocoder = new BMap.Geocoder(); //创建地址解析器的实例
      geocoder.getLocation(e.point, (res) => {
        this.editForm.compAddress = res.address;
      });
    },
    resetFields() {
      if (this.$refs.editCopanyForm) {
        this.$refs.editCopanyForm.clearValidate();
        this.$refs.editCopanyForm.resetFields();
      }
    },
    uploadSuccess(val) {
      if (val.code == 200) {
        this.editForm.compIcon = val.message;
        this.$forceUpdate();
      }
    },
  },
};
</script>

<style scoped lang="less">
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;

  border-radius: 4px;
}
.mapbox {
  height: 300px;
  width: 100%;
}

.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
</style>
